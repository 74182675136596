<template>
  <div class="house-change-container">
    <Header :info="searchInfo" />
    <el-row
      :gutter="20"
      style="
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 40px;
      "
    >
      <!--用户数据-->
      <el-col :span="24" :xs="24">
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          label-width="68px"
        >
          <el-form-item label="地区" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入地区"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          v-loading="loading"
          :data="mapList"
          border
          style="width: 100%"
        >
          <el-table-column
            label="编号"
            align="center"
            type="index"
            width="100"
          />

          <el-table-column label="地区" align="center" prop="name" />

          <el-table-column label="图片" align="center" prop="imgUrl">
            <template slot-scope="scope">
              <el-image
                :src="scope.row.imgUrl"
                style="
                  width: 80px;
                  height: 80px;
                  margin-right: 10px;
                  cursor: pointer;
                "
                :preview-src-list="[scope.row.imgUrl]"
              ></el-image>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getMapList"
        />
      </el-col>
    </el-row>
  </div>
</template>
    <script>
import Header from "@/components/Header.vue";
import Pagination from "@/components/Pagination";
export default {
  name: "HouseChange",
  components: { Header, Pagination },
  data() {
    return {
      // header数据
      searchInfo: null,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: null,
      },
      // 总数
      total: 0,
      // 房源地图列表数据
      mapList: null,
      // 加载
      loading: false,
    };
  },
  mounted() {
    this.searchInfo = this.$route.query;
    this.searchInfo.active = 3;
    this.getMapList();
  },
  methods: {
    getMapList() {
      this.loading = true;
      this.$request
        .post(
          `/api/houseDeal/queryHouseDeal?pageSize=${this.queryParams.pageSize}&pageNum=${this.queryParams.pageNum}`,
          this.queryParams
        )
        .then((response) => {
          if (response.code == 200) {
            this.mapList = response.data.rows;
            this.total = response.data.total;
          } else {
            this.$message.error(response.msg);
          }
          this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getMapList();
    },
  },
};
</script>

    <style lang="scss">
@import "../styles/variable.scss";
.house-change-container {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
</style>
